<template>
    <div class="mainWrapper pb-0">
        <section class="blockElement space bg-white">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-6 text-center">
                        <h1 class="bold mb-md-3"><span class="secondary">{{$t('calculator.text1')}}</span> {{$t('calculator.text2')}}</h1>
                        <p> {{$t('calculator.text3')}}</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space pt-0 bg-white forexTool-page">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6 mb-4">
                        <router-link :to="{name : 'pipCalculator'}" class="d-flex align-items-start align-items-center toolBox">
                            <span class="automated me-3 flex-none"><v-lazy-image class="d-block" src="/assets/images/pip.png" alt="Pip Calculator" title="The ZuluScript feature enables" /></span>
                            <span class="contentCalc">
                                <h4 class="bold">{{$t('calculator.text4')}}</h4>  
                                <p>{{$t('calculator.text5')}} </p>
                            </span> 
                        </router-link>
                    </div>
                    <div class="col-12 col-md-6 mb-4">
                        <router-link :to="{name : 'marginCalculator'}" class="d-flex align-items-start align-items-center toolBox">
                            <span class="automated me-3 flex-none"><v-lazy-image class="d-block" src="/assets/images/margin.png" alt="Margin Calculator" title="A simple yet effective way" /></span>
                            <span class="contentCalc">
                                <h4 class="bold">{{$t('calculator.text6')}}</h4>  
                                <p>{{$t('calculator.text7')}}</p>
                            </span> 
                        </router-link>
                    </div>
                    <div class="col-12 col-md-6 mb-4">
                        <router-link :to="{name : 'profitCalculator'}" class="d-flex align-items-start align-items-center toolBox">
                            <span class="automated me-3 flex-none"><v-lazy-image class="d-block" src="/assets/images/High-liquidity.svg" alt="Profit Calculator" title="A prized feature on ZuluTrade" /></span>
                            <span class="contentCalc">
                                <h4 class="bold">{{$t('calculator.text8')}}</h4>  
                                <p>{{$t('calculator.text9')}}</p>
                            </span> 
                        </router-link>
                    </div>
                    <div class="col-12 col-md-6 mb-4">
                        <router-link :to="{name : 'currencyCalculator'}" class="d-flex align-items-start align-items-center toolBox">
                            <span class="automated me-3 flex-none"><v-lazy-image class="d-block" src="/assets/images/currency.png" alt="Currency Converter" title="Interface Forex trading software " /></span>
                            <span class="contentCalc">
                                <h4 class="bold">{{$t('calculator.text10')}}</h4>  
                                <p>{{$t('calculator.text11')}}</p>
                            </span> 
                        </router-link>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space faqs automated6">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2 class="mb-md-4 pb-4">{{$t('calculator.text12')}}</h2>
                    </div>
                    <div class="col-12">
                        <div class="accordion accordion-flush" id="accordionFlushExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingOne">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    {{$t('calculator.text13')}}
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">{{$t('calculator.text14')}}</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    {{$t('calculator.text15')}}
                                    </button>
                                </h2>
                                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">{{$t('calculator.text16')}}</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    {{$t('calculator.text17')}}
                                    </button>
                                </h2>
                                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">{{$t('calculator.text18')}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>